export function formatNumberToHrk(number) {
  return new Intl.NumberFormat("hr", {
    style: "currency",
    currency: "HRK",
  })
    .format(number)
    .replace("HRK", "kn");
}

export function formatNumberToEur(number) {
  return new Intl.NumberFormat("hr", {
    style: "currency",
    currency: "EUR",
  })
    .format(number)
    .replace( "EUR", "€")
}